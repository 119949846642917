@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Be Vietnam Pro', sans-serif;
}

.custom-bullet-list {
    list-style-type: none;
    padding-left: 20px; 
    color: var(--color-grey-grey-medium, #6B7280);
    font-family: Be Vietnam Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
  
  .custom-bullet-list li::before {
    content: "-";
    margin-right: 10px;
    color: var(--color-grey-grey-medium, #6B7280);
    font-family: Be Vietnam Pro;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
  