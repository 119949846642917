.rbc-toolbar{
    font-size: 12px;
    font-weight: 500;
}

.rbc-calendar{
    background-color: #669BBC33;
    padding: 8px;
    border-radius: 12px;
}

.rbc-event.rbc-event-allday{
    background-color: #FFFFFFB2;
    border-radius: 6px;
    color:#282828;
    font-size: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 500;
}
.rbc-event{
    background-color: #FFFFFFB2;
    border-radius: 6px;
    color:#282828;
    font-size: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 500;
}

/* .rbc-toolbar-label{
    background-color: #EBF7FF;
} */